<template>
  <el-dialog :visible.sync="visible" title="公告详情" class="notice-detail">
    <div v-html="noticeInfo.content"></div>
  </el-dialog>
</template>

<script>
import Editor from "@/components/editor.vue";
export default {
  components: {
    Editor,
  },
  data() {
    return {
      visible: false,
      noticeInfo: "",
    };
  },
  methods: {
    init(obj) {
      this.visible = true;
      this.noticeInfo = obj || "";

      if (this.noticeInfo.content) {
        this.$nextTick(() => {
          this.$refs.editor.destroy();
          this.$refs.editor.init(this.noticeInfo.content);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog__header) {
    font-weight: 550;
  text-align: center;
}
</style>